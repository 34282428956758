import React from 'react';

function TextSection(){
    return (
        <section className="text-section">
        <p>Helt siden jeg var 13 har jeg eksperimentert med design og nettsider. I 2019 startet jeg mitt eget foretak for å hjelpe små og mellomstore bedrifter å kommunisere på nett.</p>
        <p>Fra den gang har jeg laget nettsider, plakater, programmer til arrangementer, kart, og mye mer.</p>
        <p><br />Jeg har mastergrad i <i>ledelse, innovasjon og markedsføring</i>, og jobber til daglig med markedsføring på UiT Norges arktiske universitet. Utdanningen og erfaringen min har gjort meg i stand til å gå dypere i <i>hvorfor</i> man tar de valgene man tar på nettsiden og i materiellet sitt.</p>
      </section>
    )
    
}


export default TextSection;