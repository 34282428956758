import React from 'react';
import logo from '../images/sfk_logo.png';


function Footer (){

    const emailAddress = "hei@sfkarlsen.no";

    const handleEmailClick = () => {
    window.location.href = `mailto:${emailAddress}`;
  };

    return(
        <section>
            <button className="contact-button" id="second-btn" onClick={handleEmailClick}>Ta kontakt</button>
            <div className="footer">
            {<img src={logo} alt="Logo" className="footer-logo" />}

                <p>&copy; Sveinung Karlsen</p>
                <p>Denne nettsiden bruker ikke cookies 🍪</p>
        
            </div>
            
            
        </section>
    );

}

export default Footer