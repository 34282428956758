import PortfolioItem from "./PortfolioItem";
import barbaImage from "../images/portfolio/barba-web.png";
import tuilImage from "../images/portfolio/TUIL-sesongkort.png";
import thikImage from "../images/portfolio/THIK-web.png";
import gttImage from "../images/portfolio/gtt-web.png";
import harstadBilImage from "../images/portfolio/harstadbil-web.png";
import mtmfImage from "../images/portfolio/mtmf-web.png";
import nffImage from "../images/portfolio/nff-kart.png";
import tordImage from "../images/portfolio/tordkarlsen-web.png";

function PortfolioSection() {
    const projects = [
      { title: "Barba", subtitle: "Nettside", imageUrl: barbaImage, projectLink: "https://barba.no" },
      { title: "TUIL", subtitle: "Kampprogram og arrangementsmateriell", imageUrl: tuilImage, projectLink: "https://tuilfotball.no" },
      { title: "Tromsø Helse- og Idrettsklinikk", subtitle: "Nettside og profil", imageUrl: thikImage, projectLink: "https://helseogidrett.no" },
      { title: "Ganddal Terapi & Trening", subtitle: "Nettside", imageUrl: gttImage, projectLink: "https://gtt.no" },
      { title: "Harstad Bil", subtitle: "Nettside", imageUrl: harstadBilImage, projectLink: "https://harstadbil.no" },
      { title: "MTMF", subtitle: "Nettside", imageUrl: mtmfImage, projectLink: "https://mtmf.no" },
      { title: "Norges Fotballforbund", subtitle: "Kart over fotballkretsene", imageUrl: nffImage, projectLink: "https://fotball.no" },
      { title: "Tord Karlsen Photography", subtitle: "Nettside", imageUrl: tordImage, projectLink: "https://tordkarlsen.com" }
    ];

  
    return (
      <section className="portfolio-section">
        <h2>Noen av de jeg har jobbet med:</h2>
        <div className="portfolio-items">
        {projects.map(project => (
          <PortfolioItem
            key={project.title}
            title={project.title}
            subtitle={project.subtitle}
            imageUrl={project.imageUrl}
            projectLink={project.projectLink}
          />
        ))}
        </div>
      </section>
    );
  }

  export default PortfolioSection
  