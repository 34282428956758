import React from 'react';
import logo from '../images/sfk_logo.png';

function MainSection() {
  const headline = "Jeg heter Sveinung";
  const subHeader = "Jeg lager nettsider, reklamemateriell, og andre fine ting.";
  const emailAddress = "hei@sfkarlsen.no";

  const handleEmailClick = () => {
    window.location.href = `mailto:${emailAddress}`;
  };

  return (
    <section className="main-section">
      {<img src={logo} alt="Logo" className="logo" />}
      <h1>{headline}</h1>
      <h2>{subHeader}</h2>
      <button className="contact-button" onClick={handleEmailClick}>Si hei!</button>
    </section>
  );
}

export default MainSection;
