function PortfolioItem({ title, subtitle, imageUrl, projectLink }) {

    const openInNewTab = () => {
        const newWindow = window.open(projectLink, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
      };

    return (
      <div className="portfolio-item" onClick={openInNewTab} style={{cursor: 'pointer'}}>
        <img src={imageUrl} alt={title} />
        <h3>{title}</h3>
        <p>{subtitle}</p>
      </div>
    );
  }

  export default PortfolioItem