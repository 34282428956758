import React from 'react';
import './App.css';
import MainSection from './components/MainSection';
import TextSection from './components/TextSection';
import PortfolioSection from './components/PortfolioSection';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <MainSection/>
      <TextSection/>
      <PortfolioSection/>
      <Footer/>
    </div>
  );
}

export default App;
